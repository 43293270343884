import React from 'react';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers';
import { BaseTextFieldProps } from '@mui/material';
import { Dayjs } from 'dayjs';
import { combineSX } from '../..';

export type DateTimePickerProps = MuiDateTimePickerProps<Dayjs>;

export default function DateTimePicker({
  format = 'DD/MM/YYYY HH:mm',
  ampm = false,
  ...rest
}: DateTimePickerProps) {
  return (
    <MuiDateTimePicker
      {...rest}
      format={format}
      ampm={ampm}
      slotProps={{
        ...rest.slotProps,
        clearButton: {
          ...rest.slotProps?.clearButton,
          sx: combineSX(
            theme => ({
              color: !rest.disabled
                ? theme.palette.primary.main
                : theme.palette.action.disabled,
              '&.clearButton': {
                opacity: 1,
              },
            }),
            (rest.slotProps?.clearButton as any)?.sx,
          ),
        },
        clearIcon: {
          ...rest.slotProps?.clearIcon,
          sx: combineSX(
            {
              width: 16,
              height: 16,
            },
            (rest.slotProps?.clearIcon as any)?.sx,
          ),
        },
        openPickerButton: {
          ...rest.slotProps?.openPickerButton,
          sx: combineSX(
            theme => ({
              color: !rest.disabled
                ? theme.palette.primary.main
                : theme.palette.action.disabled,
            }),
            (rest.slotProps?.openPickerButton as any)?.sx,
          ),
        },
        openPickerIcon: {
          ...rest.slotProps?.openPickerIcon,
          sx: combineSX(
            {
              width: 17,
              height: 17,
            },
            (rest.slotProps?.openPickerIcon as any)?.sx,
          ),
        },
        textField: {
          ...rest.slotProps?.textField,
          size: 'small',
          InputLabelProps: {
            ...(rest.slotProps?.textField as BaseTextFieldProps)
              ?.InputLabelProps,
            error: false,
          },
        },
      }}
    />
  );
}
