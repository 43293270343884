import React, { FC, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import Typography from 'components/Typography';
import Button from 'components/Button';
import useTranslation from 'hooks/useTranslation';
import { combineSX } from '../..';
import FilterSidebarGroup from './FilterSidebarGroup';

export interface FiltersSidebarProps extends BoxProps {
  groups: { title: string; content: ReactNode; active: boolean }[];
  onClear: () => void;
}

const FiltersSidebar: FC<FiltersSidebarProps> = ({ groups, onClear, sx }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={combineSX(
        {
          display: 'flex',
          flexDirection: 'column',
          width: 240,
        },
        sx,
      )}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: 1,
          borderColor: theme.palette.grey3.main,
        })}
      >
        <Typography variant="h6">{t('Filters')}</Typography>
        <Button
          variant="outlined"
          onClick={onClear}
          disabled={groups.every(group => !group.active)}
        >
          {t('Clear')}
        </Button>
      </Box>
      <Box>
        {groups.map(({ title, content, active }) => (
          <FilterSidebarGroup
            key={title}
            title={title}
            content={content}
            active={active}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FiltersSidebar;
