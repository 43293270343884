import React, { FC, useCallback, useEffect, useState } from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InputClearIconButton from '../IconButton/InputClearIconButton';
import { useDebounceCallback } from '@react-hook/debounce';
import { combineSX } from '../..';

interface TextFieldPropsPart {
  clearable?: boolean;
  debounce?: number;
}

export type TextFieldProps = TextFieldPropsPart &
  Omit<MuiTextFieldProps, keyof TextFieldPropsPart>;

const useStyles = makeStyles()(theme => ({
  root: {},
  endAdornment: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    backgroundColor: theme.palette.grey2.main,
  },
}));

const MUI_SIZES_MAP = {
  small: 'small',
  medium: 'small',
} as const;

const TextField: FC<TextFieldProps> = ({
  inputProps,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  clearable,
  value,
  onChange,
  debounce = 0,
  size = 'medium',
  classes,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles();

  const [innerValue, setInnerValue] = useState(value);
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const debouncedOnChange = useDebounceCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange?.(e);
    },
    debounce,
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setInnerValue(e.target.value);
      debouncedOnChange(e);
    },
    [debouncedOnChange],
  );

  return (
    <MuiTextField
      {...rest}
      value={innerValue}
      onChange={handleChange}
      size={MUI_SIZES_MAP[size]}
      classes={{
        ...classes,
        root: cx(innerClasses.root, classes?.root),
      }}
      inputProps={{
        ...inputProps,
        sx: combineSX(
          {
            ...(size === 'small' && {
              '&:not(.MuiInputBase-inputMultiline)': {
                py: '3.5px',
              },
            }),
          },
          inputProps?.sx,
        ),
      }}
      InputProps={{
        ...InputProps,
        endAdornment: clearable ? (
          <div className={innerClasses.endAdornment}>
            <InputClearIconButton
              hidden={!innerValue}
              onClick={() => {
                setInnerValue('');
                onChange?.({ target: { value: '' } } as any);
              }}
              disabled={rest.disabled}
            />
            {InputProps?.endAdornment}
          </div>
        ) : (
          InputProps?.endAdornment
        ),
        classes: {
          ...InputProps?.classes,
          disabled: cx(InputProps?.classes?.disabled, innerClasses.disabled),
        },
        sx: combineSX(
          {
            ...(size === 'small' && {
              '&.MuiInputBase-multiline': {
                py: '3.5px',
              },
            }),
          },
          InputProps?.sx,
        ),
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          ...InputLabelProps?.classes,
        },
        sx: combineSX(
          {
            ...(size === 'small' && {
              '&:not(.MuiInputLabel-shrink)': {
                transform: 'translate(14px, 5.5px) scale(1)',
              },
            }),
          },
          InputLabelProps?.sx,
        ),
        error: false,
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: {
          ...FormHelperTextProps?.classes,
        },
      }}
    />
  );
};

export default TextField;
