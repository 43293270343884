import React, { FC, useState, useRef } from 'react';
import { Box, Popover, Theme, useMediaQuery, BoxProps } from '@mui/material';
import UserIcon from './UserIcon';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { User } from 'components/Layout/types';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UserInfo from './UserInfo';
import SwipeableDrawer from 'components/Drawer/SwipeableDrawer';

interface UserMenuProps extends BoxProps {
  user: User;
  sidebarOpen: boolean;
  renderMenuContent: (close: () => void) => React.ReactNode;
}

const UserMenu: FC<UserMenuProps> = ({
  user,
  sidebarOpen,
  renderMenuContent,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const isLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('laptop'),
    {
      noSsr: true,
    },
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Box {...rest}>
      <Button
        onClick={handleOpen}
        ref={ref}
        sx={{
          padding: 0,
          minWidth: 'auto',
        }}
      >
        <UserIcon />
        {sidebarOpen && (
          <>
            <Typography
              className="LuiUserMenu-fullName"
              variant="h6"
              sx={{
                ml: 1,
                textTransform: 'capitalize',
              }}
            >
              {user?.name} {user?.surname}
            </Typography>
            <KeyboardArrowDownOutlinedIcon
              className="LuiUserMenu-popupIndicator"
              sx={{
                height: 18,
                width: 18,
                marginLeft: 1.5,
              }}
            />
          </>
        )}
      </Button>
      {isLaptop ? (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={ref.current}
          keepMounted
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPopover-paper': {
              mt: 1,
            },
          }}
        >
          <UserInfo user={user} />
          {renderMenuContent(handleClose)}
        </Popover>
      ) : (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          anchor="right"
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
            },
          }}
        >
          <UserInfo user={user} />
          {renderMenuContent(handleClose)}
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default UserMenu;
