import { useState, useCallback } from 'react';

/**
 * A hook to handle toggling a state variable between true and false.
 *
 * state - boolean to set current toggle state
 */
const useToggle = (initial = false): [boolean, (state?: unknown) => void] => {
  const [on, setOn] = useState(initial);

  const toggle = useCallback(
    (state: unknown) => {
      if (typeof state === 'boolean') {
        setOn(state);
      } else {
        setOn(prevState => !prevState);
      }
    },
    [setOn],
  );

  return [on, toggle];
};

export default useToggle;
