import React, { FC, useCallback } from 'react';
import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import ReactResizeDetector from 'react-resize-detector';
import { combineSX, setCssVariable } from 'helpers';
import { User } from '../types';
import UserMenu from './UserMenu';
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from 'components/Drawer/SwipeableDrawer';

export interface SideBarProps extends Partial<SwipeableDrawerProps> {
  open: boolean;
  onToggleSidebar: () => void;
  user: User;
  renderUserMenuContent: (close: () => void) => React.ReactNode;
  renderContent: (close: () => void) => React.ReactNode;
  sx?: SxProps<Theme>;
}

const SIDEBAR_CLOSED_WIDTH = 70;

const SideBar: FC<SideBarProps> = ({
  open,
  onToggleSidebar,
  user,
  renderUserMenuContent,
  renderContent,
  sx,
  ...rest
}) => {
  const isLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('laptop'),
    {
      noSsr: true,
    },
  );

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleResize = useCallback((newWidth?: number) => {
    setCssVariable('--sidebar-width', `${newWidth}px`);
  }, []);

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="left"
      onClose={onToggleSidebar}
      onOpen={onToggleSidebar}
      open={open}
      {...(isLaptop && {
        variant: 'permanent',
      })}
      sx={combineSX(
        theme => ({
          whiteSpace: 'nowrap',
          '& .MuiDrawer-paper': {
            overflow: 'hidden',
            boxSizing: 'border-box',
            width: '100%',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('laptop')]: {
              width: 'var(--sidebar-width)',
            },
          },
        }),
        sx,
      )}
      {...rest}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          [theme.breakpoints.up('laptop')]: {
            width: open ? 'fit-content' : SIDEBAR_CLOSED_WIDTH,
          },
        })}
      >
        <ReactResizeDetector handleHeight={false} onResize={handleResize} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <UserMenu
            user={user}
            sidebarOpen={open}
            renderMenuContent={close =>
              renderUserMenuContent(() => {
                close();
                !isLaptop && onToggleSidebar();
              })
            }
            sx={{
              px: 2,
              pt: 3,
              pb: 2.5,
            }}
          />
          <Box
            className="LuiLayout-sideBar-content"
            sx={{
              flexGrow: 1,
            }}
          >
            {renderContent(onToggleSidebar)}
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default SideBar;
