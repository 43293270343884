import React, { useCallback, useEffect, useState } from 'react';
import {
  MaterialReactTable,
  MRT_TableOptions,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ToggleFullScreenButton,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_Updater,
} from 'material-react-table';
import { Box, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import useTranslation from 'hooks/useTranslation';
import IconButton from 'components/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import MRT_ShowHideColumnsButton from './components/buttons/MRT_ShowHideColumnsButton';
import { MRT_BottomToolbar } from './components/toolbar/MRT_BottomToolbar';

export interface MRTProps<TData extends Record<string, any> = {}>
  extends MRT_TableOptions<TData> {
  onRefresh?: () => void;
  getIsRowDisabled?: (row: MRT_Row<TData>) => boolean;
}

const combineMRTableSxProps = ({
  theme,
  sx,
  outerSx,
}: {
  theme: Theme;
  sx: SxProps<Theme>;
  outerSx?: any;
}) => {
  return {
    ...(sx instanceof Function ? sx(theme) : sx),
    ...(outerSx instanceof Function ? outerSx(theme) : outerSx),
  };
};

export default function <TData>({
  initialState,
  state,
  onColumnOrderChange,
  onColumnPinningChange,
  displayColumnDefOptions,
  muiSearchTextFieldProps,
  muiTablePaperProps,
  muiTableContainerProps,
  muiTopToolbarProps,
  muiPaginationProps,
  muiTableBodyRowProps,
  muiTableBodyCellProps,
  renderBottomToolbarCustomActions,
  onRefresh,
  getIsRowDisabled,
  enableColumnPinning = true,
  enableStickyHeader = true,
  enableColumnResizing = true,
  enableColumnOrdering = true,
  enableRowVirtualization = true,
  enableColumnFilters = false,
  enableFullScreenToggle = true,
  enableColumnDragging = false,
  columns: originalColumns,
  ...rest
}: MRTProps<TData>) {
  const columns = originalColumns.map(column => ({
    ...column,
    ...(column.accessorFn && {
      accessorFn: (originalRow: TData) => column.accessorFn!(originalRow) ?? '',
    }),
  }));
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    state?.isFullScreen ?? false,
  );
  useEffect(() => {
    setIsFullScreen(state?.isFullScreen ?? false);
  }, [state?.isFullScreen]);

  const moveRowActionsToTheEnd = useCallback(
    (columns?: string[]) => {
      if (!rest.enableRowActions) return columns ?? [];
      return (columns ?? [])
        .filter(el => el !== 'mrt-row-actions')
        .concat(['mrt-row-actions']);
    },
    [rest.enableRowActions],
  );

  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    moveRowActionsToTheEnd(
      state?.columnOrder ?? columns.map(c => c.id! ?? c.accessorKey!),
    ),
  );
  useEffect(() => {
    state?.columnOrder &&
      setColumnOrder(moveRowActionsToTheEnd(state.columnOrder));
  }, [state?.columnOrder, moveRowActionsToTheEnd]);
  const columnOrderChangeHandler = React.useCallback(
    (updater: MRT_Updater<MRT_ColumnOrderState>) => {
      setColumnOrder(prev => {
        const newState = moveRowActionsToTheEnd(
          updater instanceof Function ? updater(prev) : updater,
        );
        onColumnOrderChange?.(newState);
        return newState;
      });
    },
    [onColumnOrderChange, moveRowActionsToTheEnd],
  );

  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>({
    ...state?.columnPinning,
    right: moveRowActionsToTheEnd(state?.columnPinning?.right),
  });
  useEffect(() => {
    state?.columnPinning &&
      setColumnPinning({
        ...state?.columnPinning,
        right: moveRowActionsToTheEnd(state?.columnPinning?.right),
      });
  }, [state?.columnPinning, moveRowActionsToTheEnd]);
  const columnPinningChangeHandler = React.useCallback(
    (updater: MRT_Updater<MRT_ColumnPinningState>) => {
      setColumnPinning(prev => {
        const newState = updater instanceof Function ? updater(prev) : updater;
        newState.right = moveRowActionsToTheEnd(newState.right);
        onColumnPinningChange?.(newState);
        return newState;
      });
    },
    [onColumnPinningChange, moveRowActionsToTheEnd],
  );

  return (
    <MaterialReactTable<TData>
      renderToolbarInternalActions={({ table }) => (
        <>
          {enableColumnFilters && <MRT_ToggleFiltersButton table={table} />}
          <MRT_ShowHideColumnsButton table={table} />
          {enableFullScreenToggle && (
            <MRT_ToggleFullScreenButton table={table} />
          )}
        </>
      )}
      {...rest}
      columns={columns}
      initialState={{
        showGlobalFilter: true,
        ...initialState,
        density: 'compact',
      }}
      state={{
        ...state,
        isLoading: undefined,
        showProgressBars: state?.isLoading,
        columnOrder,
        columnPinning,
        isFullScreen,
        density: 'compact',
      }}
      onColumnOrderChange={columnOrderChangeHandler}
      onColumnPinningChange={columnPinningChangeHandler}
      onIsFullScreenChange={setIsFullScreen}
      {...(language === 'de' && {
        localization: MRT_Localization_DE,
      })}
      positionActionsColumn="last"
      enableColumnPinning={enableColumnPinning}
      enableStickyHeader={enableStickyHeader}
      enableColumnResizing={enableColumnResizing}
      enableColumnOrdering={enableColumnOrdering}
      enableRowVirtualization={enableRowVirtualization}
      enableColumnFilters={enableColumnFilters}
      enableDensityToggle={false}
      enableFullScreenToggle={enableFullScreenToggle}
      enableColumnDragging={enableColumnDragging}
      renderBottomToolbarCustomActions={table => (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderBottomToolbarCustomActions?.(table)}
          {onRefresh && (
            <IconButton
              onClick={onRefresh}
              tooltip={t('Refresh')}
              sx={{ ml: 'auto', color: '#0000008a' }}
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
      )}
      renderBottomToolbar={({ table }) => <MRT_BottomToolbar table={table} />}
      displayColumnDefOptions={{
        ...displayColumnDefOptions,
        'mrt-row-expand': {
          enablePinning: false,
          ...displayColumnDefOptions?.['mrt-row-expand'],
          muiTableHeadCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-expand']
                ?.muiTableHeadCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
          muiTableBodyCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-expand']
                ?.muiTableBodyCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
        },
        'mrt-row-actions': {
          Header: (
            <SettingsOutlinedIcon
              sx={theme => ({
                verticalAlign: '-webkit-baseline-middle',
                color: theme.palette.text.primary,
                marginTop: '-5px',
              })}
            />
          ),
          enablePinning: false,
          ...displayColumnDefOptions?.['mrt-row-actions'],
          muiTableHeadCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-actions']
                ?.muiTableHeadCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              align: 'center',
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                    justifyContent: 'center',
                    py: 0.5,
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
          muiTableBodyCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-actions']
                ?.muiTableBodyCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                    justifyContent: 'center',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
        },
      }}
      muiSearchTextFieldProps={params => {
        const transformedComponentOuterProps =
          muiSearchTextFieldProps instanceof Function
            ? muiSearchTextFieldProps(params)
            : muiSearchTextFieldProps;

        return {
          ...transformedComponentOuterProps,
          variant: 'outlined',
          size: 'small',
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                width: 320,
                maxWidth: 320,
                '& .MuiInputAdornment-positionEnd .MuiIconButton-root': {
                  '& .MuiSvgIcon-root': {
                    //fontSize: '20px',
                  },
                  '&:not(.Mui-disabled)': {
                    color: theme.palette.primary.main,
                  },
                },
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiPaginationProps={params => {
        const transformedComponentOuterProps =
          muiPaginationProps instanceof Function
            ? muiPaginationProps(params)
            : muiPaginationProps;

        return {
          ...transformedComponentOuterProps,
          rowsPerPageOptions: [5, 10, 50, 100, 200],
        };
      }}
      muiTablePaperProps={params => {
        const transformedComponentOuterProps =
          muiTablePaperProps instanceof Function
            ? muiTablePaperProps(params)
            : muiTablePaperProps;

        return {
          ...transformedComponentOuterProps,
          elevation: 0,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                ...(isFullScreen && {
                  padding: '8px!important',
                  zIndex: '1201!important',
                }),
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableContainerProps={params => {
        const transformedComponentOuterProps =
          muiTableContainerProps instanceof Function
            ? muiTableContainerProps(params)
            : muiTableContainerProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                border: 1,
                borderRadius: 1,
                borderColor: theme.palette.border,
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTopToolbarProps={params => {
        const transformedComponentOuterProps =
          muiTopToolbarProps instanceof Function
            ? muiTopToolbarProps(params)
            : muiTopToolbarProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                '& .MuiBox-root': {
                  px: 0,
                  pt: 0,
                },
                '& .MuiCollapse-hidden': {
                  display: 'none',
                },
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableBodyRowProps={params => {
        const transformedComponentOuterProps =
          muiTableBodyRowProps instanceof Function
            ? muiTableBodyRowProps(params)
            : muiTableBodyRowProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                ...(!enableRowVirtualization && {
                  position: 'relative',
                }),
                ...(getIsRowDisabled?.(params.row) && {
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 2,
                    background: `${theme.palette.grey2.main}90`,
                  },
                }),
                ...(params.row.getIsSelected() && {
                  '&:hover td': {
                    backgroundColor: '#d0c9ff',
                  },
                }),
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableBodyCellProps={params => {
        const transformedComponentOuterProps =
          muiTableBodyCellProps instanceof Function
            ? muiTableBodyCellProps(params)
            : muiTableBodyCellProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                fontSize: '14px',
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
    />
  );
}
