import React, { FC, useCallback, ReactNode, CSSProperties } from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FilesIcon } from 'icons';
import Typography from 'components/Typography';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import ClearIcon from '@mui/icons-material/Clear';

export interface FileFieldProps extends DropzoneOptions {
  label: string;
  onChange(files: File[]): void;
  error?: boolean;
  helperText?: ReactNode;
  name?: string;
  value?: File[];
  className?: string;
  classes?: {
    root?: string;
    controlsContainer?: string;
    uploadView?: string;
    uploadViewFilled?: string;
    uploadedFileName?: string;
    filesContainer?: string;
    helperText?: string;
  };
  style?: CSSProperties;
  fullWidth?: boolean;
}

const useStyles = makeStyles<{ fullWidth?: boolean }>()(
  (theme, { fullWidth }) => ({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      outline: 'none',
      ...(fullWidth && {
        width: '100%',
      }),
    },
    controlsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    uploadView: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      border: `1px solid ${theme.palette.grey4.main}`,
      borderRadius: 2,
      padding: 18,
      boxSizing: 'border-box',
      width: '100%',
    },
    uploadViewFilled: {
      width: 65,
    },
    uploadViewError: {
      borderColor: theme.palette.error.main,
    },
    label: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.25px',
      color: theme.palette.primary.main,
      marginLeft: 20,
    },
    uploadedFileIcon: {
      color: theme.palette.text.secondary,
    },
    uploadedFileName: {
      marginLeft: 10,
      fontSize: 14,
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    helperText: {
      marginTop: 3,
      marginRight: 14,
      fontSize: '0.75rem',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '1.66',
    },
    helperTextError: {
      color: theme.palette.error.main,
    },
    file: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      width: 'fit-content',
    },
    removeFileButton: {
      marginLeft: 5,
    },
    removeFileIcon: {
      fontSize: '1rem',
    },
    filesContainer: {
      marginLeft: 10,
    },
  }),
);

const FileField: FC<FileFieldProps> = ({
  label,
  onChange,
  error,
  helperText,
  name,
  multiple = true,
  value: files = [],
  className,
  classes,
  style,
  fullWidth,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles({ fullWidth });

  const onDrop = useCallback(
    (newFiles: File[]) => {
      onChange(multiple ? [...files, ...newFiles] : newFiles);
    },
    [multiple, files, onChange],
  );

  const handleRemoveFile = useCallback(
    (fileIndex: number) => (event: any) => {
      event.stopPropagation();
      const newFilesState = files.filter((_, index) => index !== fileIndex);
      onChange(newFilesState);
    },
    [files, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    ...rest,
  });

  return (
    <div
      className={cx(className, innerClasses.root, classes?.root)}
      style={style}
      {...getRootProps()}
    >
      <div
        className={cx(
          innerClasses.controlsContainer,
          classes?.controlsContainer,
        )}
      >
        <div
          className={cx(innerClasses.uploadView, classes?.uploadView, {
            [innerClasses.uploadViewError]: error,
            [innerClasses.uploadViewFilled]: !!files.length,
            ...(classes?.uploadViewFilled && {
              [classes.uploadViewFilled]: !!files.length,
            }),
          })}
        >
          <FilesIcon />
          {!files.length && (
            <Typography className={innerClasses.label}>{label}</Typography>
          )}
        </div>
        {!!files.length && (
          <div
            className={cx(innerClasses.filesContainer, classes?.filesContainer)}
          >
            {files.map((file, index) => (
              <div className={innerClasses.file} key={index}>
                <InsertDriveFileOutlinedIcon
                  className={innerClasses.uploadedFileIcon}
                />
                <Typography
                  className={cx(
                    innerClasses.uploadedFileName,
                    classes?.uploadedFileName,
                  )}
                  tooltip
                  tooltipProps={{ disableCopy: true }}
                >
                  {file.name}
                </Typography>
                <IconButton
                  className={innerClasses.removeFileButton}
                  size="small"
                  color="primary"
                  onClick={handleRemoveFile(index)}
                >
                  <ClearIcon className={innerClasses.removeFileIcon} />
                </IconButton>
              </div>
            ))}
          </div>
        )}
      </div>
      {helperText && (
        <Typography
          className={cx(innerClasses.helperText, classes?.helperText, {
            [innerClasses.helperTextError]: error,
          })}
        >
          {helperText}
        </Typography>
      )}
      <input name={name} {...getInputProps()} />
    </div>
  );
};

export default FileField;
