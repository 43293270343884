import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { User } from '../../types';
import UserIcon from './UserIcon';
import Typography from 'components/Typography';

interface UserInfoProps extends BoxProps {
  user: User;
  onClick?: () => void;
}

const UserInfo: FC<UserInfoProps> = ({ user, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={theme => ({
        p: 1.5,
        backgroundColor: theme.palette.grey2.main,
        borderBottom: `1px solid ${theme.palette.grey3.main}`,
        ...(onClick && {
          cursor: 'pointer',
        }),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UserIcon />
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ ml: 1.5, textTransform: 'capitalize' }}
        >
          {user.name} {user.surname}
        </Typography>
      </Box>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 1 }}>
        {user.email}
      </Typography>
    </Box>
  );
};

export default UserInfo;
