import React, { useRef, FC } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Theme, InputAdornment, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TextField, { TextFieldProps } from 'components/TextField/TextField';

interface IClearableTextFieldPropsPart {
  onClear(): void;
}

export type IClearableTextFieldProps = IClearableTextFieldPropsPart &
  Omit<TextFieldProps, keyof IClearableTextFieldPropsPart>;

const useStyles = makeStyles<{
  color: NonNullable<IClearableTextFieldProps['color']>;
}>()((theme, { color }) => ({
  icon: {
    color: theme.palette[color].main,
  },
  clearButton: {
    width: 24,
    height: 24,
  },
}));

const ClearableTextField: FC<IClearableTextFieldProps> = ({
  color = 'primary',
  value,
  InputProps = {},
  onClear,
  ...rest
}) => {
  const { classes } = useStyles({ color });
  const element = useRef();

  return (
    <TextField
      {...rest}
      value={value}
      color={color}
      inputRef={element}
      InputProps={{
        ...InputProps,
        endAdornment:
          !value || !onClear ? (
            InputProps.endAdornment
          ) : (
            <InputAdornment position="end">
              <IconButton
                className={classes.clearButton}
                onClick={onClear}
                size="large"
              >
                <ClearIcon fontSize="small" className={classes.icon} />
              </IconButton>
            </InputAdornment>
          ),
      }}
    />
  );
};
export default ClearableTextField;
