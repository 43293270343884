import React, { FC, HtmlHTMLAttributes, useState } from 'react';
import { Theme, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface TabsMenuProps extends HtmlHTMLAttributes<HTMLElement> {
  disabled?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: 46,
    width: 56,
    minWidth: 56,
    borderRadius: '25px',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 30, 0.15), 0px 2px 8px 1px rgba(0, 0, 30, 0.1);',
  },
  rootDisabled: {
    background: '#0000001f',
  },
  popoverPapper: {
    padding: 16,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconDisabled: {
    color: 'unset',
  },
}));

const TabsMenu: FC<TabsMenuProps> = ({ children, disabled }) => {
  const { classes, cx } = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  return (
    <>
      <Popover
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.popoverPapper,
        }}
      >
        {children}
      </Popover>
      <Button
        className={cx(classes.root, {
          [classes.rootDisabled]: disabled,
        })}
        onClick={e => setMenuAnchorEl(e.currentTarget)}
        disabled={disabled}
      >
        <MoreVertIcon
          className={cx(classes.icon, {
            [classes.iconDisabled]: disabled,
          })}
        />
      </Button>
    </>
  );
};

export default TabsMenu;
