import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton, { IconButtonProps } from 'components/IconButton';

interface InputClearIconButtonProps extends Omit<IconButtonProps, 'onClick'> {
  hidden?: boolean;
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
}

const useStyles = makeStyles<{ hidden?: boolean; disabled?: boolean }>()(
  (theme, { hidden, disabled }) => ({
    root: {
      visibility: hidden ? 'hidden' : 'visible',
    },
    clearIcon: {
      fontSize: '1rem',
      color: !disabled
        ? theme.palette.primary.main
        : theme.palette.action.disabled,
    },
  }),
);

const InputClearIconButton: FC<InputClearIconButtonProps> = ({
  className,
  hidden,
  onClick,
  size = 'small',
  ...rest
}) => {
  const { classes, cx } = useStyles({ hidden, disabled: rest.disabled });

  return (
    <IconButton
      className={cx(className, classes.root)}
      size={size}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick(e);
      }}
      {...rest}
    >
      <ClearIcon className={classes.clearIcon} />
    </IconButton>
  );
};

export default InputClearIconButton;
