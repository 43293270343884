import React, { FC } from 'react';
import { Chip, ChipProps, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface LabelPropsPart {
  color?: string;
  children: string;
}

export type LabelProps = LabelPropsPart & Omit<ChipProps, keyof LabelPropsPart>;

const LABEL_BACKGROUND_OPACITY_PERCENTAGE = 40;
const LABEL_BACKGROUND_HOVER_OPACITY_PERCENTAGE = 25;

const useStyles = makeStyles<{ color: string }>()((_theme, { color }) => ({
  root: {
    backgroundColor: `${color}${LABEL_BACKGROUND_OPACITY_PERCENTAGE}`,
    maxWidth: '-webkit-fill-available',
    border: `1px solid ${color}`,
    '&.MuiChip-clickable:focus': {
      backgroundColor: `${color}${LABEL_BACKGROUND_OPACITY_PERCENTAGE}`,
    },
    '&.MuiChip-clickable:hover': {
      backgroundColor: `${color}${LABEL_BACKGROUND_HOVER_OPACITY_PERCENTAGE}`,
    },
  },
  label: {
    fontSize: '10px',
    lineHeight: '13px',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
    color: color,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const Label: FC<LabelProps> = ({
  className,
  color = '#ababab',
  children,
  ...rest
}) => {
  const { classes, cx } = useStyles({ color });
  return (
    <Chip
      className={cx(className, classes.root)}
      label={
        <Typography className={classes.label} title={children}>
          {children}
        </Typography>
      }
      size="small"
      {...rest}
    />
  );
};

export default Label;
