import React, { FC } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

interface UserIconProps extends BoxProps {}

const UserIcon: FC<UserIconProps> = () => {
  return (
    <Box
      className="LuiUserMenu-icon"
      sx={theme => ({
        borderRadius: '4px',
        width: 38,
        height: 38,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#604dff33',
      })}
    >
      <FaceOutlinedIcon color="primary" />
    </Box>
  );
};

export default UserIcon;
