import React, { forwardRef } from 'react';
import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  LinearProgress,
  Tooltip,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { combineSX } from 'helpers';
import Typography from '../Typography';

export interface ButtonProps extends Omit<MuiButtonProps, 'css'> {
  loading?: boolean;
  minimizeForMobiles?: boolean;
  unsavedChanges?: boolean;
  //error?: boolean;
  tooltip?: TooltipProps['title'] | null;
  tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      loading,
      startIcon,
      endIcon,
      minimizeForMobiles,
      unsavedChanges,
      //error,
      variant = 'text',
      color = 'secondary',
      tooltip,
      tooltipProps,
      onClick,
      sx,
      ...rest
    },
    ref,
  ) => {
    const disabled = rest.disabled || loading;
    const theme = useTheme();
    const isUpTablet = useMediaQuery(theme.breakpoints.up('tablet'), {
      noSsr: true,
    });
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const button = (
      <MuiButton
        sx={combineSX(
          theme => ({
            position: 'relative',
            overflow: 'hidden',
            minWidth: 'auto',
            textTransform: 'none',
            ...(variant === 'outlined' &&
              color === 'secondary' && {
                border: 1,
                borderColor: 'grey3.main',
                backgroundColor: 'white',
              }),
            ...{
              text: theme.typography.subtitle1,
              outlined: theme.typography.body1,
              contained: theme.typography.body1,
            }[variant],
            ...(disabled && {
              backgroundColor: theme.palette.grey2.main,
            }),
            ...(unsavedChanges && {
              '&:after': {
                content: '"*"',
                position: 'absolute',
                top: 6,
                right: 3,
                fontSize: 25,
                // ...(error && {
                //   color: theme.palette.error.main,
                // }),
              },
            }),
            '&.Mui-disabled': {
              pointerEvents: 'all',
            },
          }),
          sx,
        )}
        ref={ref}
        startIcon={minimizeForMobiles ? isUpTablet && startIcon : startIcon}
        endIcon={minimizeForMobiles ? isUpTablet && endIcon : endIcon}
        variant={variant}
        color={color}
        {...rest}
        disabled={disabled}
        {...(!disabled && { onClick })}
      >
        <Box
          className="LuiButton-content"
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          })}
        >
          {minimizeForMobiles && (startIcon || endIcon) && !isUpTablet ? (
            <>
              {startIcon}
              {endIcon}
            </>
          ) : typeof children === 'string' ? (
            <Typography
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
        {loading && (
          <LinearProgress
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          />
        )}
      </MuiButton>
    );

    return tooltip ? (
      <Tooltip
        title={tooltip}
        disableInteractive
        open={tooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
        {...tooltipProps}
      >
        {button}
      </Tooltip>
    ) : (
      <>{button}</>
    );
  },
);

export default Button;
