import React, { FC, useCallback, useEffect } from 'react';
import Button, { ButtonProps } from './Button';
import useTranslation from 'hooks/useTranslation';

export interface ConfirmButtonProps extends ButtonProps {
  label?: string;
  onClick: () => void;
}

const ConfirmButton: FC<ConfirmButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  label,
  ...rest
}) => {
  const { t } = useTranslation();

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'Enter' && !rest.disabled) {
        event.preventDefault();
        event.stopPropagation();

        rest.onClick();
      }
    },
    [rest.onClick, rest.disabled],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return (
    <Button color={color} variant={variant} {...rest}>
      {label || t('confirm')}
    </Button>
  );
};

export default ConfirmButton;
