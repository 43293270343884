import React, { FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import { Theme, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Typography from 'components/Typography';
import useToggle from 'hooks/useToggle';
import { formatDate } from '../utils';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import useTranslation from 'hooks/useTranslation';

const useStyles = makeStyles()((theme: Theme) => ({
  messageWrapper: {
    width: '70%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  message: {
    padding: theme.spacing(1.5),
    borderRadius: 4,
  },
  messageAuthor: {
    fontWeight: 'bold',
  },
  messageText: {
    color: '#828282',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  messageDate: {
    color: '#828282',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  inner: {
    marginLeft: 'auto',
  },
  outer: {
    marginRight: 'auto',
  },
}));

export interface MessageProps {
  author?: string;
  message: string;
  inner: boolean;
  date: string;
  remark?: boolean;
  classes?: {
    message?: string;
    messageWrapper?: string;
    messageAuthor?: string;
    messageText?: string;
    messageDate?: string;
  };
}

const Message: FC<MessageProps> = ({
  author,
  message,
  inner,
  date,
  remark,
  classes,
}) => {
  const { classes: innerClasses, cx } = useStyles();
  const [showDate, toggleShowDate] = useToggle(false);
  const { t } = useTranslation();

  return (
    <Box
      className={cx(
        { [innerClasses.inner]: inner, [innerClasses.outer]: !inner },
        innerClasses.messageWrapper,
        classes?.messageWrapper,
      )}
    >
      <Box
        className={cx(innerClasses.message, classes?.message)}
        onClick={toggleShowDate}
        sx={{
          backgroundColor: author ? '#f2f2f2' : '#bcc9e2',
          position: 'relative',
        }}
      >
        {author && (
          <Typography
            className={cx(innerClasses.messageAuthor, classes?.messageAuthor)}
            color="primary"
            noWrap
            tooltip
            tooltipProps={{
              disableCopy: true,
            }}
          >
            {author}
          </Typography>
        )}
        <Typography
          color="textSecondary"
          className={cx(innerClasses.messageText, classes?.messageText)}
        >
          {sanitizeHtml(message, { allowedTags: [], allowedAttributes: {} })}
        </Typography>
        {remark && (
          <Tooltip
            title={t('Remark created without notifying the WeDeclare team')}
          >
            <NotificationsOffIcon
              sx={{
                color: '#6f7072',
                position: 'absolute',
                right: 4,
                bottom: 4,
                height: 18,
                width: 18,
              }}
            />
          </Tooltip>
        )}
      </Box>
      {showDate && (
        <Typography
          variant="caption"
          className={cx(innerClasses.messageDate, classes?.messageDate)}
        >
          {formatDate(date)}
        </Typography>
      )}
    </Box>
  );
};

export default Message;
