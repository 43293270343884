import React from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { combineSX } from '../..';

export type DatePickerProps = MuiDatePickerProps<Dayjs>;

function DatePicker({ format = 'DD/MM/YYYY', ...rest }: DatePickerProps) {
  return (
    <MuiDatePicker
      {...rest}
      format={format}
      slotProps={{
        ...rest.slotProps,
        clearButton: {
          ...rest.slotProps?.clearButton,
          sx: combineSX(
            theme => ({
              color: !rest.disabled
                ? theme.palette.primary.main
                : theme.palette.action.disabled,
              '&.clearButton': {
                opacity: 1,
              },
            }),
            (rest.slotProps?.clearButton as any)?.sx,
          ),
        },
        clearIcon: {
          ...rest.slotProps?.clearIcon,
          sx: combineSX(
            {
              width: 16,
              height: 16,
            },
            (rest.slotProps?.clearIcon as any)?.sx,
          ),
        },
        openPickerButton: {
          ...rest.slotProps?.openPickerButton,
          sx: combineSX(
            theme => ({
              color: !rest.disabled
                ? theme.palette.primary.main
                : theme.palette.action.disabled,
            }),
            (rest.slotProps?.openPickerButton as any)?.sx,
          ),
        },
        openPickerIcon: {
          ...rest.slotProps?.openPickerIcon,
          sx: combineSX(
            {
              width: 17,
              height: 17,
            },
            (rest.slotProps?.openPickerIcon as any)?.sx,
          ),
        },
        textField: {
          ...rest.slotProps?.textField,
          size: 'small',
          InputLabelProps: {
            ...(rest.slotProps?.textField as any)?.InputLabelProps,
            error: false,
          },
        },
      }}
    />
  );
}

export default DatePicker;
