import React, { createContext, FC, useEffect } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as yup from 'yup';
import dayjs from 'dayjs';
import i18n from 'i18n';
import 'pure-react-carousel/dist/react-carousel.es.css';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export type LanguageType = 'en' | 'de';

interface UiLibraryProviderProps extends React.PropsWithChildren {
  language: LanguageType;
}

export const UiLibraryContext = createContext<{
  language?: LanguageType;
}>({});

const UiLibraryProvider: FC<UiLibraryProviderProps> = ({
  children,
  language,
}) => {
  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: { key: 'mixedRequired' },
        notType: ({ type }: any) => ({ key: 'notType', values: { type } }),
      },
      string: {
        min: ({ min }: any) => ({ key: 'stringMin', values: { min } }),
        max: ({ max }: any) => ({ key: 'stringMax', values: { max } }),
        email: { key: 'stringEmail' },
      },
      number: {
        min: ({ min }: any) => ({ key: 'numberMin', values: { min } }),
        max: ({ max }: any) => ({ key: 'numberMax', values: { max } }),
        integer: ({ integer }: any) => ({
          key: 'numberInteger',
          values: { integer },
        }),
      },
      array: {
        min: ({ min }: any) => ({ key: 'arrayMin', values: { min } }),
        max: ({ max }: any) => ({ key: 'arrayMax', values: { max } }),
      },
      date: {
        min: ({ min }: any) => ({ key: 'dateMin', values: { min } }),
        max: ({ max }: any) => ({ key: 'dateMax', values: { max } }),
      },
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <UiLibraryContext.Provider
      value={{
        language,
      }}
    >
      <StyledEngineProvider injectFirst>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          {children}
        </LocalizationProvider>
      </StyledEngineProvider>
    </UiLibraryContext.Provider>
  );
};

export default UiLibraryProvider;
