import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material';
//import RubikLight from './fonts/Rubik-Light.woff2';
//import RubikRegular from './fonts/Rubik-Regular.woff2';
//import RubikMedium from './fonts/Rubik-Medium.woff2';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    grey1: PaletteColor;
    grey2: PaletteColor;
    grey3: PaletteColor;
    grey4: PaletteColor;
    border: string;
  }
  interface PaletteOptions {
    grey1: PaletteColorOptions;
    grey2: PaletteColorOptions;
    grey3: PaletteColorOptions;
    grey4: PaletteColorOptions;
    border: string;
  }
}

// const rubikGeneral = {
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
// };

// const rubikLight = {
//   ...rubikGeneral,
//   fontFamily: 'Rubik-Light',
//   fontWeight: 300,
//   src: `
//     local('Rubik'),
//     local('Rubik-Light'),
//     url(${RubikLight}) format('woff2')
//   `,
// };

// const rubik = {
//   ...rubikGeneral,
//   fontFamily: 'Rubik',
//   fontWeight: 400,
//   src: `
//     local('Rubik'),
//     local('Rubik-Regular'),
//     url(${RubikRegular}) format('woff2')
//   `,
// };

// const rubikBold = {
//   ...rubikGeneral,
//   fontFamily: 'Rubik-Medium',
//   fontWeight: 600,
//   src: `
//     local('Rubik'),
//     local('Rubik-Medium'),
//     url(${RubikMedium}) format('woff2')
//   `,
// };

const COLORS = {
  primary: '#604DFF',
  secondary: '#2A2C36',
  paragraphDark: '#2A2C36',
  paragraphLight: '#737A96',
  grey1: '#FAF9FB',
  grey2: '#F3F4FB',
  grey3: '#C9C9DE',
  grey4: '#969BAB',
};

const theme = createTheme({
  typography: palette => ({
    fontFamily: 'Rubik',
    h1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 35,
      lineHeight: '41px',
      mixBlendMode: 'normal',
    },
    h2: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '33px',
      mixBlendMode: 'normal',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '21px',
      mixBlendMode: 'normal',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px',
      mixBlendMode: 'normal',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      mixBlendMode: 'normal',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0.5,
      mixBlendMode: 'normal',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.5,
      mixBlendMode: 'normal',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0.5,
      mixBlendMode: 'normal',
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '10px',
      letterSpacing: 0.25,
      textTransform: 'uppercase',
      mixBlendMode: 'normal',
    },
  }),
  components: {
    /*MuiCssBaseline: {
      '@global': {
        '@font-face': [rubik, rubikLight, rubikBold],
      },
    }, */
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'calc(50% - 12px)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        //size: 'small',
        autoComplete: 'off',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& fieldset': {
            borderColor: COLORS.grey3,
          },
        },
        input: {
          minHeight: '23px!important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          borderRadius: '4px',
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...theme.typography.body1,
          textTransform: 'capitalize',
          ...(ownerState.color === 'primary' && {
            color: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.grey3.main}`,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              border: `1px solid ${theme.palette.primary.main}`,
              color: 'white',
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}`,
              },
            },
          }),
        }),
      },
    },
  },
  palette: {
    primary: {
      main: COLORS.primary,
      light: '#604dff1a',
    },
    secondary: {
      main: COLORS.secondary,
    },
    text: {
      primary: COLORS.paragraphDark,
      secondary: COLORS.paragraphLight,
    },
    grey1: {
      main: COLORS.grey1,
    },
    grey2: {
      main: COLORS.grey2,
    },
    grey3: {
      main: COLORS.grey3,
    },
    grey4: {
      main: COLORS.grey4,
    },
    border: COLORS.grey3,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

export default theme;
