import React, { ReactNode } from 'react';
import {
  Theme,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Tab from './Tab';
import TabsMenu from './TabsMenu';

export interface TabsProps
  extends Omit<MuiTabsProps, 'onChange' | 'children' | 'classes' | 'value'> {
  tabs: { value: any; label: string }[];
  selected: any;
  onChange: (newTab: any) => void;
  controls?: ReactNode;
  classes?: MuiTabsProps['classes'] & {
    tabs?: string;
  };
  tabsMenuContent?: ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(),
    gridTemplate: `
      "tabsMenu controls controls"
      "tabs     tabs      tabs"
      /1fr      1fr       1fr
    `,
    [theme.breakpoints.up('sm')]: {
      gridTemplate: `
      "tabsMenu tabs  controls"
      /auto     1fr   auto
    `,
    },
  },
  tabs: {
    gridArea: 'tabs',
    borderRadius: '25px',
    background: theme.palette.grey2.main,
    minHeight: 46,
  },
  tabsMenu: {
    gridArea: 'tabsMenu',
  },
  controls: {
    gridArea: 'controls',
  },
  scroller: {
    padding: 4,
  },
  scrollButtons: {
    width: 20,
  },
  indicator: {
    display: 'none',
  },
}));

export default function Tabs({
  className,
  tabs,
  classes,
  controls,
  selected,
  onChange,
  tabsMenuContent,
  variant = 'scrollable',
  scrollButtons = 'auto',
  ...rest
}: TabsProps) {
  const { classes: innerClasses, cx } = useStyles();

  return (
    <div className={cx(className, classes?.root, innerClasses.root)}>
      {tabsMenuContent && (
        <TabsMenu className={innerClasses.tabsMenu} disabled={!tabsMenuContent}>
          {tabsMenuContent}
        </TabsMenu>
      )}
      <MuiTabs
        classes={{
          ...classes,
          root: cx(classes?.tabs, innerClasses.tabs),
          indicator: cx(classes?.indicator, innerClasses.indicator),
          scroller: cx(classes?.scroller, innerClasses.scroller),
          scrollButtons: cx(classes?.scrollButtons, innerClasses.scrollButtons),
        }}
        value={selected}
        onChange={(e, newTab) => onChange(newTab)}
        variant={variant}
        scrollButtons={scrollButtons}
        {...rest}
      >
        {tabs.map(tab => (
          <Tab key={tab.value} {...tab} />
        ))}
      </MuiTabs>
      {controls && <div className={innerClasses.controls}>{controls}</div>}
    </div>
  );
}
