import React, { ReactElement, forwardRef } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    minHeight: theme.spacing(10),
    padding: theme.spacing(1.5),
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    borderBottom: '2px solid #F1F4F8',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: 1.2,
    fontWeight: 400,
    paddingBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    fontWeight: 400,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
  closeButton: {
    marginLeft: 'auto',
    height: '100%',
  },
}));

interface ChatHeaderProps {
  AvatarComponent?: ReactElement;
  title?: string;
  subTitle?: string;
  classes?: {
    container?: string;
    avatarContainer?: string;
    closeButton?: string;
    title?: string;
    subTitle?: string;
  };
  onClose?: () => void;
  onHide?: () => void;
}

const ChatHeader = forwardRef(
  (
    {
      AvatarComponent,
      title,
      subTitle,
      classes,
      onClose,
      onHide,
    }: ChatHeaderProps,
    ref: any,
  ) => {
    const { classes: innerClasses, cx } = useStyles();

    return (
      <Grid
        item
        container
        direction="row"
        className={cx(innerClasses.header, classes?.container)}
        ref={ref}
      >
        {AvatarComponent && (
          <Box className={cx(innerClasses.avatar, classes?.avatarContainer)}>
            {AvatarComponent}
          </Box>
        )}
        <Box minWidth={0} flex={1}>
          {title && (
            <Typography
              className={cx(innerClasses.title, classes?.title)}
              noWrap
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              className={cx(innerClasses.subTitle, classes?.subTitle)}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
        {(onHide || onClose) && (
          <Box className={cx(innerClasses.closeButton, classes?.closeButton)}>
            {onHide && (
              <IconButton
                size="small"
                color="inherit"
                aria-label="hide"
                onClick={() => onHide()}
              >
                <MinimizeIcon />
              </IconButton>
            )}
            {onClose && (
              <IconButton
                size="small"
                color="inherit"
                aria-label="close"
                onClick={() => onClose()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Grid>
    );
  },
);

export default ChatHeader;
