import React, { FC, ReactNode } from 'react';
import SideBar, { SideBarProps } from './SideBar';
import Content, { ContentProps } from './Content';
import { User } from './types';
import { useLayoutContext } from './LayoutContext';

export interface LayoutProps extends React.PropsWithChildren {
  user: User;
  renderUserMenuContent: (close: () => void) => ReactNode;
  renderSidebarContent: (close: () => void) => ReactNode;
  contentProps?: Partial<ContentProps>;
  sidebarProps?: Partial<SideBarProps>;
}

const Layout: FC<LayoutProps> = ({
  user,
  renderUserMenuContent,
  renderSidebarContent,
  children,
  contentProps,
  sidebarProps,
}) => {
  const {
    layoutState: { sidebarOpen },
    layoutActions: { setSidebarOpen },
  } = useLayoutContext();

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <SideBar
        open={sidebarOpen}
        onToggleSidebar={handleToggleSidebar}
        user={user}
        renderUserMenuContent={renderUserMenuContent}
        renderContent={renderSidebarContent}
        {...sidebarProps}
      />
      <Content sidebarOpen={sidebarOpen} {...contentProps}>
        {children}
      </Content>
    </>
  );
};

export default Layout;
