import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Box, BoxProps, Skeleton, Tab, Tabs } from '@mui/material';
import Loader from 'components/Loader';
import { combineSX, setCssVariable } from 'helpers';
import { useLayoutContext } from 'components/Layout/LayoutContext';
import IconButton from 'components/IconButton';
import Typography from 'components/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Resizer from 'components/Resizer';
import { useResizeDetector } from 'react-resize-detector';
import clsx from 'clsx';

interface PageContainerProps extends Omit<BoxProps, 'title'> {
  title: ReactNode;
  controls?: ReactNode;
  loading?: boolean;
  tabs?: React.ReactNode[];
  selectedTab?: number;
  onTabChange?: (newTab: number) => void;
  sideDrawerContent?: ReactNode;
  sideDrawerWidth?: number;
  sideDrawerMaxWidth?: number;
  onSideDrawerResize?: (newWidth: number) => void;
  ContentContainerProps?: BoxProps;
}

const PageContainer: FC<PageContainerProps> = ({
  title,
  controls,
  loading,
  tabs,
  selectedTab = 0,
  onTabChange,
  sideDrawerContent,
  sideDrawerWidth,
  sideDrawerMaxWidth,
  onSideDrawerResize,
  children,
  sx,
  ContentContainerProps,
  ...rest
}) => {
  const { height: appBarHeight, ref: appBarResizerRef } = useResizeDetector({
    onResize: (width, height) => {
      setCssVariable('--appbar-height', `${height}px`);
    },
  });
  const [innerSelectedTab, setInnerSelectedTab] = useState(selectedTab);
  useEffect(() => setInnerSelectedTab(selectedTab), [selectedTab]);

  const { layoutState, layoutActions } = useLayoutContext();

  return (
    <Box
      className="LuiPageContainer-root"
      sx={combineSX(
        theme => ({
          minHeight: '100vh',
          display: 'flex',
          gap: 2,
          backgroundColor: theme.palette.grey2.main,
        }),
        sx,
      )}
      {...rest}
    >
      <Box
        sx={{
          flexGrow: 1,
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          ref={appBarResizerRef}
          className="LuiPageContainer-appBar"
          sx={theme => ({
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.grey2.main,
            zIndex: 1100,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 2,
              py: 2.5,
              gap: 2,
              minHeight: 40,
              boxSizing: 'unset',
              ...(sideDrawerContent && {
                pr: 0,
              }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                minWidth: 0,
              }}
            >
              <IconButton
                className="LuiPageContainer-appBar-sideBarToggler"
                onClick={() => {
                  layoutActions.setSidebarOpen(!layoutState.sidebarOpen);
                }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              {(() => {
                if (!title)
                  return <Skeleton width={150} height={24} animation="wave" />;
                if (['string', 'number'].includes(typeof title))
                  return (
                    <Typography
                      className="LuiPageContainer-appBar-title"
                      variant="h2"
                      noWrap
                      sx={{
                        color: '#474A57',
                      }}
                    >
                      {title}
                    </Typography>
                  );

                return title;
              })()}
            </Box>
            <Box className="LuiPageContainer-appBar-controls">{controls}</Box>
          </Box>
          {!!tabs?.length && (
            <Tabs
              value={innerSelectedTab}
              onChange={(e, newSelectedTab) => {
                setInnerSelectedTab(newSelectedTab);
                onTabChange?.(newSelectedTab);
              }}
              indicatorColor="primary"
              sx={{
                minHeight: 'unset',
                px: 2,
              }}
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={i}
                  label={tab}
                  sx={theme => ({
                    minHeight: 'unset',
                    py: 1,
                    ...theme.typography.body1,
                    textTransform: 'none',
                    '& .Mui-selected': {},
                  })}
                />
              ))}
            </Tabs>
          )}
        </Box>
        <Box
          {...ContentContainerProps}
          className={clsx(
            'LuiPageContainer-content',
            ContentContainerProps?.className,
          )}
          sx={combineSX(
            theme => ({
              background: 'white',
              p: 2,
              flexGrow: 1,
              overflowY: 'auto',
              ...(sideDrawerContent && {
                borderTop: 1,
                borderRight: 1,
                borderColor: theme.palette.grey3.main,
                borderTopRightRadius: 4,
                maxHeight: `calc(100vh - ${appBarHeight}px)`,
              }),
            }),
            ContentContainerProps?.sx,
          )}
        >
          {loading ? <Loader /> : children}
        </Box>
      </Box>
      {sideDrawerContent && (
        <Resizer
          {...(onSideDrawerResize && {
            onResize: ({ width }) => onSideDrawerResize(width),
          })}
          left
          width={sideDrawerWidth}
          maxWidth={sideDrawerMaxWidth}
          sx={{
            height: '100vh',
            position: 'sticky',
            top: 0,
            background: 'white',
          }}
        >
          {sideDrawerContent}
        </Resizer>
      )}
    </Box>
  );
};
export default PageContainer;
