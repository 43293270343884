import React, { FC, memo, useCallback } from 'react';
import Button, { ButtonProps } from '../Button/Button';
import { useFormikContext } from 'formik';
import useTranslation from 'hooks/useTranslation';
//import { flatten } from 'flat';
import { useSnackbar } from 'notistack';

export interface FormSubmitButtonProps extends ButtonProps {
  label?: string;
  notifyOnError?: boolean;
}

const MemoButton: FC<ButtonProps> = memo(props => <Button {...props} />);

const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  label,
  children,
  onClick,
  unsavedChanges,
  //error,
  notifyOnError,
  ...rest
}) => {
  const { dirty, submitForm, isSubmitting, validateForm } = useFormikContext(); //, errors, touched
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (notifyOnError) {
        validateForm().then(errors => {
          if (Object.keys(errors).length) {
            enqueueSnackbar(t('Form contains errors'), {
              variant: 'error',
            });
          }
        });
      }
      submitForm();
      onClick?.(e);
    },
    [submitForm, onClick, enqueueSnackbar, t, notifyOnError],
  );

  return (
    <MemoButton
      color={color}
      variant={variant}
      onClick={handleClick}
      loading={isSubmitting}
      disabled={isSubmitting}
      unsavedChanges={unsavedChanges ?? dirty}
      // error={
      //   error ??
      //   (() => {
      //     const flatErrors = flatten(errors) as any;
      //     const flatTouched = flatten(touched) as any;
      //     return Object.keys(flatErrors).some(
      //       key => flatTouched[key.replace(/\.key$/, '')],
      //     );
      //   })()
      // }
      {...rest}
    >
      {label || children || t('save')}
    </MemoButton>
  );
};

export default FormSubmitButton;
