import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams,
} from '@mui/material';
import TextField, { TextFieldProps } from '../TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { combineSX } from 'helpers';
import clsx from 'clsx';
import useTranslation from 'hooks/useTranslation';

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  InputProps?: TextFieldProps;
}

export default function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  InputProps,
  sx,
  ...rest
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { t } = useTranslation();

  return (
    <MuiAutocomplete
      noOptionsText={t('No options')}
      popupIcon={
        <ExpandMoreIcon
          sx={theme => ({
            fontSize: '1.25rem',
            ...(!rest.disabled && {
              color: theme.palette.primary.main,
            }),
          })}
        />
      }
      renderInput={(params: MuiAutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            {...InputProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps?.InputProps,
              className: clsx(
                params.InputProps.className,
                InputProps?.InputProps?.className,
              ),
              sx: combineSX(
                {
                  ...(rest.size === 'small' && {
                    '&.MuiAutocomplete-inputRoot': {
                      py: '3.5px!important',
                    },
                  }),
                },
                InputProps?.InputProps?.sx,
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...InputProps?.inputProps,
              className: clsx(
                params.inputProps.className,
                InputProps?.inputProps?.className,
              ),
              sx: combineSX(
                {
                  ...(rest.size === 'small' && {
                    py: '0px!important',
                  }),
                },
                InputProps?.inputProps?.sx,
              ),
            }}
          />
        );
      }}
      {...rest}
      sx={combineSX(
        theme => ({
          '& .MuiAutocomplete-clearIndicator': {
            color: theme.palette.primary.main,
            visibility: 'visible',
            '& .MuiSvgIcon-fontSizeSmall': {
              fontSize: '1rem',
            },
          },
          '&.MuiAutocomplete-root': {
            '& .MuiOutlinedInput-root': {
              '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon&': {
                paddingRight: '50px',
              },
            },
          },
        }),
        sx,
      )}
    />
  );
}
