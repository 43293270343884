import React, { HtmlHTMLAttributes, ReactNode } from 'react';
import {
  Theme,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  IconButton,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Typography from 'components/Typography';

export interface SubTableRow<T> {
  data: T;
  selected?: boolean;
  multiSelectable?: boolean;
  multiSelected?: boolean;
  disabled?: boolean;
}

export interface SubTableRows<T> {
  [key: string]: SubTableRow<T>;
}

export interface SubTableColumn<T> {
  header?: string;
  headerClassName?: string;
  cell?: (row: SubTableRow<T>) => ReactNode;
  cellClassName?: string;
}

export interface SubTableColumns<T> {
  [key: string]: SubTableColumn<T>;
}

export interface SubTableProps<T>
  extends Omit<HtmlHTMLAttributes<HTMLElement>, 'onSelect'> {
  columns: SubTableColumns<T>;
  rows: SubTableRows<T>;
  onSelect?: (newRows: SubTableRows<T>, dobuleClick: boolean) => void;
  onMultiSelect?: (
    newRows: SubTableRows<T>,
    toggledRow: SubTableRow<T>,
  ) => void;
  classes?: {
    root?: string;
  };
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  headerCell: {
    background: theme.palette.grey2.main,
  },
  bodyRow: {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  bodyRowSelected: {
    background: 'rgba(96, 77, 255, 0.1)',
  },
  cell: {
    boxSizing: 'border-box',
    height: 35,
    padding: '5px 12px',
    '&:first-of-type': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableBodyCell: {
    display: 'flex',
    alignItems: 'center',
  },
  controllerCell: {
    width: '0%',
    minWidth: 66,
    maxWidth: 66,
  },
}));

export default function SubTable<T>({
  columns,
  rows,
  onSelect,
  onMultiSelect,
  classes,
}: SubTableProps<T>) {
  const { classes: innerClasses, cx } = useStyles();
  const theme = useTheme<Theme>();

  const handleSelect = (
    selectedRowIdentifier: string,
    selectedRow: SubTableRow<T>,
    dobuleClick: boolean,
  ) => {
    if (selectedRow.disabled) return;
    onSelect?.(
      Object.entries(rows).reduce((acc, [rowIdentifier, row]) => {
        acc[rowIdentifier] = {
          ...row,
          selected: selectedRowIdentifier === rowIdentifier,
        };
        return acc;
      }, {} as SubTableRows<T>),
      dobuleClick,
    );
  };

  const handleMultiSelect = (
    selectedRowIdentifier: string,
    selectedRow: SubTableRow<T>,
  ) => {
    if (selectedRow.disabled) return;
    onMultiSelect?.(
      Object.entries(rows).reduce((acc, [rowId, row]) => {
        acc[rowId] = {
          ...row,
          //selected: selectedRowIdentifier === rowId,
          ...(selectedRowIdentifier === rowId && {
            multiSelected: !selectedRow.multiSelected,
          }),
        };
        return acc;
      }, {} as SubTableRows<T>),
      selectedRow,
    );
  };

  return (
    <MuiTable className={cx(innerClasses.root, classes?.root)}>
      <MuiTableHead>
        <MuiTableRow>
          <MuiTableCell
            className={cx(
              innerClasses.cell,
              innerClasses.controllerCell,
              innerClasses.headerCell,
            )}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: 0,
                  borderLeft: `2px dashed ${theme.palette.grey4.main}`,
                }}
              />
            </div>
          </MuiTableCell>
          {onMultiSelect && (
            <MuiTableCell
              className={cx(
                innerClasses.cell,
                innerClasses.controllerCell,
                innerClasses.headerCell,
              )}
            />
          )}
          {Object.entries(columns).map(([columnId, column]) => (
            <MuiTableCell
              className={cx(
                innerClasses.cell,
                innerClasses.headerCell,
                column.headerClassName,
              )}
              key={columnId}
            >
              <Typography variant="overline" color="textSecondary">
                {column.header}
              </Typography>
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {Object.entries(rows).map(([rowId, row], i) => (
          <MuiTableRow
            className={cx(innerClasses.bodyRow, {
              [innerClasses.bodyRowSelected]: row.selected,
            })}
            key={rowId}
            onClick={() => handleSelect(rowId, row, false)}
            onDoubleClick={() => handleSelect(rowId, row, true)}
          >
            <MuiTableCell
              className={cx(innerClasses.cell, innerClasses.controllerCell)}
            >
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'end',
                }}
              >
                <div
                  style={{
                    height:
                      i === Object.keys(rows).length - 1
                        ? 'calc(50% + 1px)'
                        : '100%',
                    width: 0,
                    borderLeft: `2px dashed ${theme.palette.grey4.main}`,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      height: 0,
                      width: 14,
                      borderTop: `2px dashed ${theme.palette.grey4.main}`,
                    }}
                  />
                </div>
              </div>
            </MuiTableCell>
            {onMultiSelect && (
              <MuiTableCell
                className={cx(innerClasses.cell, innerClasses.controllerCell)}
              >
                {row.multiSelectable !== false && (
                  <IconButton
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      handleMultiSelect(rowId, row);
                    }}
                  >
                    {row.multiSelected ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM16.58 7.58L17.99 9L9.99 17L5.99 13.01L7.41 11.6L9.99 14.17L16.58 7.58Z"
                          fill="#604DFF"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19 19V5H5V19H19Z"
                          fill="#0000008a"
                        />
                      </svg>
                    )}
                  </IconButton>
                )}
              </MuiTableCell>
            )}
            {Object.entries(columns).map(([columnId, column]) => (
              <MuiTableCell
                className={cx(innerClasses.cell, column.cellClassName)}
                key={columnId}
              >
                {
                  (column.cell?.(row) ||
                    row.data[columnId as keyof T]) as ReactNode
                }
              </MuiTableCell>
            ))}
          </MuiTableRow>
        ))}
      </MuiTableBody>
    </MuiTable>
  );
}
