import React, { FC } from 'react';
import { Theme, Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface TabProps extends MuiTabProps {}

const useStyles = makeStyles()(theme => ({
  root: {
    ...(theme.typography.body1 as any),
    borderRadius: '25px',
    textTransform: 'none',
    minWidth: 64,
    height: 38,
    minHeight: 38,
  },
  selected: {
    background: 'white',
    color: theme.palette.primary.main,
  },
}));

const Tab: FC<TabProps> = ({ classes, ...rest }) => {
  const { classes: innerClasses, cx } = useStyles();

  return (
    <MuiTab
      classes={{
        ...classes,
        root: cx(innerClasses.root, classes?.root),
        selected: cx(innerClasses.selected, classes?.selected),
      }}
      {...rest}
    />
  );
};

export default Tab;
