import React, { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

export interface ContentProps extends BoxProps {
  sidebarOpen: boolean;
}

const Content: FC<ContentProps> = ({ sidebarOpen, children }) => {
  return (
    <Box
      sx={theme => ({
        boxSizing: 'border-box',
        maxWidth: '100vw',
        minHeight: '100vh',
        [theme.breakpoints.up('laptop')]: {
          paddingLeft: `var(--sidebar-width)`,
          transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: sidebarOpen
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen,
          }),
        },
      })}
    >
      {children}
    </Box>
  );
};

export default Content;
