import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from 'components/Button';
import CancelButton from 'components/Button/CancelButton';
import ConfirmButton from 'components/Button/ConfirmButton';
import useTranslation from 'hooks/useTranslation';
import { Columns } from '../Table';
import {
  DragDropContext,
  Droppable,
  DraggableLocation,
} from 'react-beautiful-dnd';
import ColumnCard from './ColumnCard';
import { Column } from 'components';
import chunk from 'lodash/chunk';
import Typography from 'components/Typography';

interface ColumnsCustomizationMenuProps<T> {
  columns: Columns<T>;
  onChange(newColumns: Columns<T>): void;
  itemsPerColumn?: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  popoverContent: {
    padding: '8px 16px',
  },
  columnsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  popoverActions: {
    padding: 16,
    background: '#FAFAFE',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  clearFilter: {
    marginLeft: 6,
  },
  activeDot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    right: -7.5,
    top: -7.5,
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: 11,
  },
}));

const COLUMN_SIZE = 12;

function reorder<T>(
  list: [string, Column<T>][],
  startIndex: number,
  endIndex: number,
) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function reorderColumnsMap<T>({
  columnsMap,
  source,
  destination,
  itemsPerColumn,
}: {
  columnsMap: {
    [key: string]: [string, Column<T>][];
  };
  source: DraggableLocation;
  destination: DraggableLocation;
  itemsPerColumn: number;
}) {
  const current = [...columnsMap[source.droppableId]];
  const next = [...columnsMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...columnsMap,
      [source.droppableId]: reordered,
    };

    return result;
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...columnsMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return chunk(
    Object.entries(result).reduce<[string, Column<T>][]>(
      (acc, [, columns]) => [...acc, ...columns],
      [],
    ),
    itemsPerColumn,
  ).reduce<{
    [key: string]: [string, Column<T>][];
  }>((acc, chunk, i) => ({ ...acc, [i]: chunk }), {});
}

export default function ColumnsCustomizationMenu<T>({
  columns,
  onChange,
  itemsPerColumn = COLUMN_SIZE,
}: ColumnsCustomizationMenuProps<T>) {
  const transfomredColumnsChunks = useMemo(
    () =>
      chunk(
        Object.entries(columns)
          .filter(([, column]) => !column.actionsColumn)
          .sort(([, { pos: pos1 }], [, { pos: pos2 }]) => {
            return (pos1 as number) - (pos2 as number);
          }),
        itemsPerColumn,
      ).reduce<{
        [key: string]: [string, Column<T>][];
      }>((acc, chunk, i) => ({ ...acc, [i]: chunk }), {}),
    [columns],
  );
  const { t } = useTranslation('table');
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [innerColumnsChunks, setInnerColumnsChunks] = useState(
    transfomredColumnsChunks,
  );

  useEffect(() => {
    setInnerColumnsChunks(transfomredColumnsChunks);
  }, [transfomredColumnsChunks]);

  const { classes: innerClasses, cx } = useStyles();

  const handleConfirm = () => {
    let pos = 1;
    onChange({
      ...Object.entries(innerColumnsChunks).reduce<Columns<T>>(
        (acc1, [, transformedColumns]) => ({
          ...acc1,
          ...transformedColumns.reduce<Columns<T>>(
            (acc2, [identifier, transformedColumn]) => {
              transformedColumn.pos = pos;
              pos += 1;
              return {
                ...acc2,
                [identifier]: transformedColumn,
              };
            },
            {},
          ),
        }),
        {},
      ),
      ...Object.entries(columns).reduce<Columns<T>>(
        (acc, [identifier, column]) => {
          if (column.actionsColumn) {
            column.pos = pos;
            pos += 1;
            acc[identifier] = column;
          }
          return acc;
        },
        {},
      ),
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setInnerColumnsChunks(transfomredColumnsChunks);
    setIsOpen(false);
  };

  return (
    <div className={innerClasses.root}>
      <Button
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
        ref={filterButtonRef}
        endIcon={<SettingsIcon />}
        minimizeForMobiles
      >
        {t('Columns')}
      </Button>
      {isOpen && (
        <Popover
          open
          anchorEl={filterButtonRef.current}
          onClose={handleCancel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            mt: 1,
          }}
        >
          <Box
            sx={theme => ({
              p: 2,
              borderBottom: `1px solid ${theme.palette.grey3.main}`,
            })}
          >
            <Typography variant="h6">{t('Columns settings')}</Typography>
            <Typography color="textSecondary" sx={{ mt: 0.5 }}>
              {t('Rearrange and hide/unhide the listed columns')}
            </Typography>
          </Box>
          <div className={cx(innerClasses.popoverContent)}>
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                if (!destination) {
                  return;
                }
                // const sourceColumnIndex = Number(source.droppableId);
                // const destinationColumnIndex = Number(destination.droppableId);
                // if (
                //   (sourceColumnIndex > destinationColumnIndex &&
                //     destination.index === itemsPerColumn) ||
                //   (sourceColumnIndex < destinationColumnIndex &&
                //     destination.index === 0)
                // ) {
                //   return;
                // }

                setInnerColumnsChunks(
                  reorderColumnsMap({
                    columnsMap: innerColumnsChunks,
                    source,
                    destination,
                    itemsPerColumn,
                  }),
                );

                // if (destination.index === source.index) {
                //   return;
                // }

                // setInnerColumns(prev =>
                //   Object.entries(prev).reduce(
                //     (
                //       acc,
                //       [identifier, column]: [keyof Columns<T>, Column<T>],
                //     ) => {
                //       let newPos = column.pos as number;
                //       if (column.pos === source.index)
                //         newPos = destination.index;
                //       else if (
                //         isNumberInRange(
                //           column.pos as number,
                //           destination.index,
                //           source.index,
                //         )
                //       )
                //         newPos =
                //           source.index > destination.index
                //             ? newPos + 1
                //             : newPos - 1;
                //       acc[identifier] = {
                //         ...column,
                //         pos: newPos,
                //       };
                //       return acc;
                //     },
                //     {} as Columns<T>,
                //   ),
                // );
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                }}
              >
                {Object.entries(innerColumnsChunks).map(([chunk, columns]) => (
                  <Droppable droppableId={chunk} key={chunk}>
                    {provided => (
                      <div
                        className={innerClasses.columnsList}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {columns.map(([identifier, innerColumn], i) => (
                          <ColumnCard
                            key={identifier}
                            identifier={identifier}
                            column={innerColumn}
                            index={i}
                            onVisibilityChange={newVisible => {
                              setInnerColumnsChunks(prev => {
                                return {
                                  ...prev,
                                  [chunk]: prev[chunk].map(([id, column]) => [
                                    id,
                                    {
                                      ...column,
                                      visible:
                                        id === identifier
                                          ? newVisible
                                          : column.visible,
                                    },
                                  ]),
                                };
                              });
                            }}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </Box>
            </DragDropContext>
          </div>
          <div className={innerClasses.popoverActions}>
            <CancelButton onClick={handleCancel} />
            <ConfirmButton
              onClick={handleConfirm}
              label={t('common:apply')}
              style={{ marginLeft: 8 }}
            />
          </div>
        </Popover>
      )}
    </div>
  );
}
