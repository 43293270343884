import React, { useMemo, useState, ReactNode, useCallback } from 'react';
import {
  Theme,
  Collapse,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TableBodyCell from './TableBodyCell';
import { TableBodyProps } from './TableBody';
import { Row } from '../Table';

import TableBodyExpandCell from './TableBodyExpandCell';
import TableBodyMultiSelectCell from './TableBodyMultiSelectCell';

interface TableBodyRowProps<T> extends Pick<TableBodyProps<T>, 'columns'> {
  row: Row<T>;
  onSelect?: (row: Row<T>, doubleClick: boolean) => void;
  //onMiddleClick?: () => void;
  onMultiSelect?: (
    row: Row<T>,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  renderExpandedRow?: (rowData: any) => ReactNode;
}

const useStyles = makeStyles<{
  selected?: boolean;
  expanded?: boolean;
  expandable?: boolean;
}>()((theme, { selected }) => ({
  row: {
    height: 48,
  },
  rowExpandedStartAdornment: {
    position: 'relative',
    '&:after': {
      content: '""',
      width: 4,
      height: 'calc(100% + 1px)',
      background: '#716EDE',
      position: 'absolute',
      right: 'calc(100% - 4px)',
    },
  },
  mainRow: {
    ...(selected && {
      background: 'rgba(96, 77, 255, 0.1)',
    }),
    '&:not(:last-child) td': {
      borderBottom: `1px solid ${theme.palette.grey3.main}`,
    },
  },
  subRowCell: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.grey3.main}`,
  },
  controllerCell: {
    width: '1%',
  },
}));

function TableBodyRow<T>({
  columns,
  row,
  renderExpandedRow,
  onSelect,
  onMultiSelect,
}: //onMiddleClick,
TableBodyRowProps<T>) {
  const [expanded, setExpanded] = useState(false);
  const { classes, cx } = useStyles(
    useMemo(
      () => ({
        selected: row.selected,
        expanded,
        expandable: row.expandable,
      }),
      [row.selected, expanded, row.expandable],
    ),
  );

  const handleExpand = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  return (
    <>
      <MuiTableRow
        className={cx(classes.row, classes.mainRow, {
          [classes.rowExpandedStartAdornment]: expanded,
        })}
        onClick={!row.disabled ? () => onSelect?.(row, false) : undefined}
        onDoubleClick={!row.disabled ? () => onSelect?.(row, true) : undefined}
        // {...(onMiddleClick && {
        //   onMouseDown: (e: React.MouseEvent) => {
        //     if (e.button === 1) {
        //       e.preventDefault();
        //     }
        //   },
        //   onMouseUp: (e: React.MouseEvent) => {
        //     if (e.button === 1) {
        //       onMiddleClick();
        //     }
        //   },
        // })}
      >
        {renderExpandedRow && (
          <TableBodyExpandCell
            row={row}
            expanded={expanded}
            onExpand={handleExpand}
          />
        )}
        {onMultiSelect && (
          <TableBodyMultiSelectCell
            row={row}
            onMultiSelect={e => onMultiSelect(row, e)}
          />
        )}
        {Object.entries(columns)
          .filter(([, column]) => column.visible !== false)
          .sort(([, { pos: pos1 }], [, { pos: pos2 }]) => {
            return (pos1 as number) - (pos2 as number);
          })
          .map(([identifier, column]) => {
            return (
              <TableBodyCell
                key={identifier}
                className={cx(
                  column.cellClassName,
                  `table-body-cell-${identifier}`,
                )}
                width={column.width}
                sx={column.columnSX}
                disabled={row.disabled}
                actionsColumn={column.actionsColumn}
                identifier={identifier}
                cell={column.cell}
                row={row}
              />
            );
          })}
      </MuiTableRow>
      {expanded && (
        <MuiTableRow
          className={cx(classes.row, {
            [classes.rowExpandedStartAdornment]: expanded,
          })}
        >
          <MuiTableCell
            className={classes.subRowCell}
            colSpan={
              Object.keys(columns).length + 1 + Number(Boolean(onMultiSelect))
            }
          >
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {renderExpandedRow?.(row)}
            </Collapse>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </>
  );
}

export default React.memo(TableBodyRow) as typeof TableBodyRow;
