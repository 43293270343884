import React, { useMemo } from 'react';
import { Theme, TableBody as MuiTableBody } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TableBodyRow from './TableBodyRow';
import { TableProps, Rows, Row } from '../Table';
import Typography from 'components/Typography';
import { SubTableRows } from '../SubTable';
import useTranslation from 'hooks/useTranslation';

export interface TableBodyProps<T>
  extends Pick<
    TableProps<T>,
    | 'columns'
    | 'rows'
    | 'dataIdentifier'
    | 'onSelect'
    | 'onMultiSelect'
    | 'renderExpandedRow'
  > {
  noData: boolean;
}

const useStyles = makeStyles()(theme => ({
  root: {},
  noData: {
    textAlign: 'center',
  },
}));

function getRowIdentifier<T>(
  dataIdentifier: TableBodyProps<T>['dataIdentifier'],
  row: Row<T>,
) {
  return Array.isArray(dataIdentifier)
    ? JSON.stringify(
        dataIdentifier.reduce(
          (acc, el) => ({
            ...acc,
            [el]: row.data[el],
          }),
          {} as any,
        ),
      )
    : String(row.data[dataIdentifier]);
}

function TableBody<T>({
  columns,
  rows,
  dataIdentifier,
  onSelect,
  onMultiSelect,
  renderExpandedRow,
  noData,
}: TableBodyProps<T>) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const denormalizedActiveRows = useMemo(
    () =>
      Object.entries(rows)
        .filter(([, row]) => row.activePos !== undefined)
        .map(([, row]) => row)
        .sort((a, b) => (a.activePos as number) - (b.activePos as number)),
    [rows],
  );

  const handleSelect = React.useCallback(
    (
      selectedRow: Row<T>,
      dobuleClick: boolean,
      //middleClick?: boolean,
    ) => {
      const selectedRowIdentifier = getRowIdentifier(
        dataIdentifier,
        selectedRow,
      );
      onSelect?.(
        Object.entries(rows).reduce(
          (rowsAcc, [rowIdentifier, row]: [keyof Rows<T>, Row<T>]) => {
            let newRow = row;
            if (row.selected) {
              newRow = {
                ...newRow,
                selected: false,
              };
            }
            if (selectedRowIdentifier === rowIdentifier) {
              newRow = {
                ...newRow,
                selected: true,
              };
            }
            if (
              row.subTableRows &&
              Object.entries(row.subTableRows).some(
                ([subTableRowId, subTableRow]) => subTableRow.selected,
              )
            ) {
              newRow = {
                ...newRow,
                subTableRows: Object.entries(row.subTableRows).reduce(
                  (subRowsAcc, [subTableRowId, subTableRow]) => {
                    let newSubTableRow = subTableRow;
                    if (subTableRow.selected) {
                      newSubTableRow = {
                        ...newSubTableRow,
                        selected: false,
                      };
                    }

                    subRowsAcc[subTableRowId] = newSubTableRow;
                    return subRowsAcc;
                  },
                  {} as SubTableRows<any>,
                ),
              };
            }

            rowsAcc[rowIdentifier] = newRow;
            return rowsAcc;
          },
          {} as Rows<T>,
        ),
        dobuleClick,
        //middleClick,
      );
    },
    [dataIdentifier, rows, onSelect],
  );

  const handleMultiSelect = React.useCallback(
    (
      selectedRow: Row<T>,
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      const selectedRowIdentifier = getRowIdentifier(
        dataIdentifier,
        selectedRow,
      );
      const toggledRows = [] as Row<T>[];
      if (e.shiftKey) {
        const indexOfCurrentClicked = denormalizedActiveRows.findIndex(row => {
          if (Array.isArray(dataIdentifier))
            return dataIdentifier.every(
              el => row.data[el] === selectedRow.data[el],
            );
          return row.data[dataIdentifier] === selectedRow.data[dataIdentifier];
        });

        const indexOfLastClicked = denormalizedActiveRows.findIndex(
          row => row.selected,
        );

        const [startIndex, endIndex] =
          indexOfCurrentClicked > indexOfLastClicked
            ? [indexOfLastClicked, indexOfCurrentClicked]
            : [indexOfCurrentClicked, indexOfLastClicked];
        toggledRows.push(
          ...denormalizedActiveRows
            .slice(startIndex, endIndex + 1)
            .filter(row => !row.multiSelected),
        );
      } else {
        toggledRows.push(selectedRow);
      }

      onMultiSelect?.(
        Object.entries(rows).reduce(
          (rowsAcc, [rowIdentifier, row]: [keyof Rows<T>, Row<T>]) => {
            const toggledRow = toggledRows.some(toggledRowItem => {
              if (Array.isArray(dataIdentifier))
                return dataIdentifier.every(
                  key => toggledRowItem.data[key] === row.data[key],
                );
              return (
                toggledRowItem.data[dataIdentifier] === row.data[dataIdentifier]
              );
            });
            rowsAcc[rowIdentifier] = {
              ...row,
              ...(onSelect && {
                selected: selectedRowIdentifier === rowIdentifier,
              }),
              ...(toggledRow && {
                multiSelected: !selectedRow.multiSelected,
              }),
              ...(row.subTableRows && {
                ...(toggledRow && {
                  multiSelected: !Object.values(row.subTableRows).some(
                    subTableRow => subTableRow.multiSelected,
                  ),
                }),
                subTableRows: Object.entries(row.subTableRows).reduce(
                  (subRowsAcc, [subTableRowId, subTableRow]) => {
                    subRowsAcc[subTableRowId] = {
                      ...subTableRow,
                      selected: false,
                      ...(toggledRow && {
                        multiSelected: !Object.values(
                          row.subTableRows as SubTableRows<T>,
                        ).some(el => el.multiSelected),
                      }),
                    };
                    return subRowsAcc;
                  },
                  {} as SubTableRows<T>,
                ),
              }),
            };
            return rowsAcc;
          },
          {} as Rows<T>,
        ),
        selectedRow,
      );
    },
    [dataIdentifier, rows, onMultiSelect],
  );

  return (
    <MuiTableBody className={classes.root}>
      {!denormalizedActiveRows.length && (
        <tr style={{ borderTop: '0.1px solid #D9D5E9', height: 54 }}>
          <td colSpan={9999}>
            {noData && (
              <Typography
                className={classes.noData}
                variant="subtitle1"
                color="textSecondary"
              >
                {t('table:noData')}
              </Typography>
            )}
          </td>
        </tr>
      )}
      {denormalizedActiveRows.map(row => {
        return (
          <TableBodyRow<T>
            key={getRowIdentifier(dataIdentifier, row)}
            columns={columns}
            row={row}
            onSelect={onSelect && handleSelect}
            onMultiSelect={onMultiSelect && handleMultiSelect}
            // onMiddleClick={onSelect && handleSelect(identifier, false, true)}
            renderExpandedRow={renderExpandedRow}
          />
        );
      })}
    </MuiTableBody>
  );
}

export default React.memo(TableBody) as typeof TableBody;
