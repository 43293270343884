import React, { useState } from 'react';
import useTranslation from 'hooks/useTranslation';

import Typography from '../Typography';

interface IMagnifierProps {
  magnifierHeight?: number;
  magnifierWidth?: number;
  zoomLevel?: number;
  children: any;
  enabled?: boolean;
  imageSrc?: string;
  tooltipColor?: 'light' | 'dark';
}

const Magnifier = ({
  magnifierHeight = 200,
  magnifierWidth = 400,
  zoomLevel = 2.5,
  children,
  enabled = true,
  imageSrc,
  tooltipColor = 'light',
}: IMagnifierProps) => {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [allowMagnifierMovement, setAllowMagnifierMovement] = useState(true);
  const { t } = useTranslation();

  const tooltipVariant = {
    light: { background: 'white' },
    dark: { background: 'black' },
  };

  const tooltipStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
    background: tooltipVariant[tooltipColor].background,
    opacity: 0.7,
  };

  return (
    <>
      <div
        onMouseEnter={e => {
          setAllowMagnifierMovement(true);

          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          enabled && setShowMagnifier(true);
        }}
        onMouseMove={e => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          const newX = e.pageX - left - window.pageXOffset;
          const newY = e.pageY - top - window.pageYOffset;
          allowMagnifierMovement && setXY([newX, newY]);
        }}
        onMouseLeave={() => {
          allowMagnifierMovement && setShowMagnifier(false);
        }}
        onClick={() => {
          setAllowMagnifierMovement(!allowMagnifierMovement);
        }}
      >
        {children}
        <div
          style={{
            display: showMagnifier ? '' : 'none',
            zIndex: 2,
            position: 'absolute',

            // prevent magnifier blocks the mousemove event of img
            pointerEvents: 'none',
            // set size of magnifier
            height: `${magnifierHeight}px`,
            width: `${magnifierWidth}px`,
            // move element center to cursor pos
            top: `${y - magnifierHeight / 2}px`,
            left: `${x - magnifierWidth / 2}px`,
            opacity: '1', // reduce opacity so you can verify position
            border: `1px solid  ${tooltipVariant[tooltipColor].background}`,
            borderRadius: '4px',
            backgroundColor: 'white',
            backgroundImage: `url('${imageSrc}')`,
            backgroundRepeat: 'no-repeat',

            //calculate zoomed image size
            backgroundSize: `${imgWidth * zoomLevel}px ${
              imgHeight * zoomLevel
            }px`,

            //calculate position of zoomed image.
            backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
          }}
        >
          <div style={{ ...tooltipStyle }}>
            <Typography
              variant="overline"
              style={{
                color: tooltipColor === 'light' ? '#000000' : '#ffffff',
              }}
            >
              {allowMagnifierMovement
                ? t('magnifier:pin')
                : t('magnifier:unpin')}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Magnifier;
