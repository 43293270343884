import React, { FC, useMemo, useCallback } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Theme,
  Typography,
  TypographyVariant,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DialogTitle from './components/DialogTitle';
import DialogContent from './components/DialogContent';
import DialogActions from './components/DialogActions';
import { green, orange, red, grey } from '@mui/material/colors';
import ThumbUpRounded from '@mui/icons-material/ThumbUpRounded';
import WarningRounded from '@mui/icons-material/WarningRounded';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import InfoRounded from '@mui/icons-material/InfoRounded';
import useTranslation from 'hooks/useTranslation';
import { CancelButtonProps as CancelButtonPropsType } from 'components/Button/CancelButton';
import { ConfirmButtonProps as ConfirmButtonPropsType } from 'components/Button/ConfirmButton';

export type AlertDialogVariant = 'success' | 'warning' | 'error' | 'notice';

interface DialogProps extends Omit<MuiDialogProps, 'open'> {
  open?: boolean;
  variant: AlertDialogVariant;
  title?: string;
  onClose: () => void;
  onConfirm: () => void;
  CancelButtonProps?: CancelButtonPropsType;
  ConfirmButtonProps?: ConfirmButtonPropsType;
}

const useStyles = makeStyles()((theme: Theme) => ({
  dialogTitle: {
    textAlign: 'center',
    color: 'white',
    padding: theme.spacing(3),
  },
  titleText: {
    fontWeight: 300,
  },
  variantIcon: {
    fontSize: 94,
    marginTop: 10,
  },
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: orange[600],
  },
  error: {
    backgroundColor: red[600],
  },
  notice: {
    backgroundColor: grey[600],
  },
  dialogTitleCloseButton: {
    color: 'white',
  },
  dialogContent: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
}));

const AlertDialog: FC<DialogProps> = ({
  children,
  onClose,
  onConfirm,
  open = true,
  variant,
  title,
  maxWidth = 'xs',
  fullWidth = true,
  CancelButtonProps,
  ConfirmButtonProps,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const isCloseDisabled = ConfirmButtonProps?.loading;

  const handleClose = useCallback(() => {
    if (!isCloseDisabled) onClose();
  }, [onClose, isCloseDisabled]);

  const {
    title: defaultTitle,
    Icon,
    typographyVariant,
  } = useMemo(() => {
    return {
      success: {
        title: 'success',
        Icon: ThumbUpRounded,
      },
      warning: {
        title: 'warning',
        Icon: WarningRounded,
        typographyVariant: 'h5' as TypographyVariant,
      },
      error: {
        title: 'error',
        Icon: ErrorOutlineRounded,
      },
      notice: {
        title: 'notice',
        Icon: InfoRounded,
      },
    }[variant];
  }, [variant]);

  return (
    <MuiDialog
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={handleClose}
      {...rest}
    >
      <DialogTitle
        className={cx(classes.dialogTitle, classes[variant])}
        onClose={handleClose}
        isCloseDisabled={isCloseDisabled}
        classes={{
          closeButton: classes.dialogTitleCloseButton,
        }}
      >
        <Typography
          align="center"
          variant={typographyVariant}
          className={classes.titleText}
        >
          {title || t(defaultTitle)}
        </Typography>
        <Icon className={classes.variantIcon} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions
        onConfirm={onConfirm}
        onClose={handleClose}
        CancelButtonProps={{
          ...CancelButtonProps,
          ...(isCloseDisabled && { disabled: isCloseDisabled }),
        }}
        ConfirmButtonProps={ConfirmButtonProps}
      />
    </MuiDialog>
  );
};

export default AlertDialog;
