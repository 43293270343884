import React, { FC } from 'react';
import { DialogActions as MuiDialogActions, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CancelButton, {
  CancelButtonProps,
} from 'components/Button/CancelButton';
import ConfirmButton, {
  ConfirmButtonProps,
} from 'components/Button/ConfirmButton';

export interface DialogActionsProps {
  onClose: () => void;
  onConfirm: () => void;
  CancelButtonProps?: Partial<CancelButtonProps>;
  ConfirmButtonProps?: Partial<ConfirmButtonProps>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5),
    background: theme.palette.grey2.main,
  },
}));

const DialogActions: FC<DialogActionsProps> = ({
  onClose,
  onConfirm,
  CancelButtonProps,
  ConfirmButtonProps,
}) => {
  const { classes: innerClasses } = useStyles();
  return (
    <MuiDialogActions className={innerClasses.root}>
      <CancelButton onClick={onClose} {...CancelButtonProps} />
      <ConfirmButton onClick={onConfirm} {...ConfirmButtonProps} />
    </MuiDialogActions>
  );
};

export default DialogActions;
