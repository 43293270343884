import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { InputAdornment, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import ClearableTextField, {
  IClearableTextFieldProps,
} from '../TextField/ClearableTextField';
import useTranslation from 'hooks/useTranslation';

export interface SearchProps
  extends Omit<IClearableTextFieldProps, 'onChange' | 'onClear'> {
  onChange(value: string): void;
  waitTime?: number;
}

const useStyles = makeStyles<{ color: NonNullable<SearchProps['color']> }>()(
  (theme: Theme, { color }) => ({
    icon: {
      color: theme.palette[color].main,
    },
    clearButton: {
      width: 24,
      height: 24,
    },
  }),
);

const Search = ({
  onChange,
  waitTime = 500,
  value = '',
  color = 'primary',
  label,
  ...others
}: SearchProps): ReactElement => {
  const { classes } = useStyles({ color });
  const [searchValue, setSearchValue] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const debouncedSearch = useDebounceCallback(onChange, waitTime);

  const handleChange = useCallback(
    (searchString: string) => {
      setSearchValue(searchString);
      debouncedSearch(searchString);
    },
    [debouncedSearch],
  );

  return (
    <ClearableTextField
      {...others}
      label={label || `${t('search')}...`}
      color={color}
      value={searchValue}
      onChange={e => handleChange(e.target.value)}
      onClear={() => handleChange('')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
