import React, { FC } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button, { ButtonProps } from '../Button';

export interface TabsControlButtonProps extends ButtonProps {}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: 46,
    borderRadius: 25,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
}));

const TabsControlButton: FC<TabsControlButtonProps> = ({
  className,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles();

  return (
    <Button
      className={cx(className, innerClasses.root)}
      variant="contained"
      color="primary"
      {...rest}
    />
  );
};

export default TabsControlButton;
