import React, { FC, useRef, useState } from 'react';
import { IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Popover from '@mui/material/Popover';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from 'components/Button';
import CancelButton from 'components/Button/CancelButton';
import ConfirmButton from 'components/Button/ConfirmButton';
import useTranslation from 'hooks/useTranslation';
import ClearIcon from '@mui/icons-material/Clear';

export interface FilterMenuProps extends React.PropsWithChildren {
  activeCount: number;
  onCancel(): void;
  onConfirm(): void;
  onClear(): void;
  classes?: {
    popoverContent?: string;
  };
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  popoverContent: {
    padding: 16,
  },
  popoverActions: {
    padding: 16,
    background: '#FAFAFE',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  clearFilter: {
    marginLeft: 6,
  },
  activeDot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 15,
    height: 15,
    borderRadius: '50%',
    right: -7.5,
    top: -7.5,
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: 11,
  },
}));

const FilterMenu: FC<FilterMenuProps> = ({
  activeCount,
  children,
  onCancel,
  onConfirm,
  onClear,
  classes,
}) => {
  const { t } = useTranslation();
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isTabletMobile = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const [isOpen, setIsOpen] = useState(false);

  const { classes: innerClasses, cx } = useStyles();

  return (
    <div className={innerClasses.root}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
        ref={filterButtonRef}
        sx={{
          ...(!isOpen && { borderColor: '#C8C8D4' }),
          paddingLeft: 1,
          paddingRight: 1,
          overflow: 'unset',
        }}
      >
        <FilterListIcon />
        {Boolean(activeCount) && (
          <div className={innerClasses.activeDot}>{activeCount}</div>
        )}
      </Button>
      {Boolean(activeCount) &&
        (isTabletMobile ? (
          <CancelButton
            className={innerClasses.clearFilter}
            label={t('filterMenu:clearFilter')}
            onClick={() => {
              onClear();
            }}
          />
        ) : (
          <IconButton
            className={innerClasses.clearFilter}
            size="small"
            onClick={() => {
              onClear();
            }}
          >
            <ClearIcon />
          </IconButton>
        ))}

      {isOpen && (
        <Popover
          open
          anchorEl={filterButtonRef.current}
          onClose={() => {
            setIsOpen(false);
            onCancel();
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            className={cx(innerClasses.popoverContent, classes?.popoverContent)}
          >
            {children}
          </div>
          <div className={innerClasses.popoverActions}>
            <CancelButton
              onClick={() => {
                setIsOpen(false);
                onCancel();
              }}
            />
            <ConfirmButton
              onClick={() => {
                setIsOpen(false);
                onConfirm();
              }}
              label={t('apply')}
              style={{ marginLeft: 8 }}
            />
          </div>
        </Popover>
      )}
    </div>
  );
};

export default FilterMenu;
