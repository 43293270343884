import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { combineSX } from 'helpers';

export type ActionBarProps = GridProps;

const ActionBar: FC<ActionBarProps> = ({ sx, ...rest }) => {
  return (
    <Grid
      sx={combineSX(
        {
          padding: 1.5,
          background: '#fff',
          borderRadius: '4px',
          border: 1,
          borderColor: 'grey3.main',
        },
        sx,
      )}
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      {...rest}
    />
  );
};

export default ActionBar;
