import React, { FC, CSSProperties } from 'react';

interface FilesIconProps {
  style?: CSSProperties;
}

const FilesIcon: FC<FilesIconProps> = ({ style }) => {
  return (
    <svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: 24, minHeight: 24, maxWidth: 24, maxHeight: 24 }}
    >
      <path
        opacity="0.7"
        d="M17.7072 8.01114C17.7072 7.99862 17.7025 7.98656 17.694 7.97735L13.6534 3.59436C13.6306 3.56962 13.5985 3.55554 13.5648 3.55554C13.4983 3.55554 13.4443 3.60949 13.4443 3.67603V7.26676C13.4443 7.7054 13.7999 8.06098 14.2386 8.06098H17.6573C17.6849 8.06098 17.7072 8.03867 17.7072 8.01114Z"
        fill="#221ECD"
      />
      <path
        d="M4.05566 22.3169C4.05566 22.7555 4.41125 23.1111 4.84989 23.1111H17.267C17.7056 23.1111 18.0612 22.7555 18.0612 22.3169V8.17776H14.4821C14.0435 8.17776 13.6879 7.82218 13.6879 7.38354V3.55554H4.84989C4.41125 3.55554 4.05566 3.91113 4.05566 4.34977V22.3169Z"
        fill="white"
      />
      <path
        d="M13.6879 3.55554H4.84989C4.41125 3.55554 4.05566 3.91113 4.05566 4.34977V22.3169C4.05566 22.7555 4.41125 23.1111 4.84989 23.1111H17.267C17.7056 23.1111 18.0612 22.7555 18.0612 22.3169V8.17776M13.6879 3.55554V3.55554C13.8012 3.55554 13.9093 3.60294 13.9862 3.68625L18.013 8.05437C18.044 8.08799 18.0612 8.13204 18.0612 8.17776V8.17776M13.6879 3.55554V7.38354C13.6879 7.82218 14.0435 8.17776 14.4821 8.17776H18.0612"
        stroke="#221ECD"
        strokeWidth="0.907616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8416 9.77771H6.72217"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2545 12.4443H6.72217"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2545 15.1111H6.72217"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2545 17.7777H6.72217"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.7"
        d="M20.3737 5.34451C20.3737 5.33199 20.369 5.31993 20.3605 5.31073L16.3199 0.927737C16.2971 0.902992 16.265 0.888916 16.2313 0.888916C16.1648 0.888916 16.1108 0.94286 16.1108 1.0094V4.60013C16.1108 5.03877 16.4664 5.39436 16.9051 5.39436H20.3238C20.3514 5.39436 20.3737 5.37204 20.3737 5.34451Z"
        fill="#221ECD"
      />
      <path
        d="M6.72217 19.6502C6.72217 20.0889 7.07776 20.4445 7.5164 20.4445H19.9335C20.3721 20.4445 20.7277 20.0889 20.7277 19.6502V5.51114H17.1486C16.71 5.51114 16.3544 5.15555 16.3544 4.71691V0.888916H7.5164C7.07776 0.888916 6.72217 1.2445 6.72217 1.68314V19.6502Z"
        fill="white"
      />
      <path
        d="M16.3544 0.888916H7.5164C7.07776 0.888916 6.72217 1.2445 6.72217 1.68314V19.6502C6.72217 20.0889 7.07776 20.4445 7.5164 20.4445H19.9335C20.3721 20.4445 20.7277 20.0889 20.7277 19.6502V5.51114M16.3544 0.888916V0.888916C16.4677 0.888916 16.5758 0.936311 16.6527 1.01963L20.6795 5.38774C20.7105 5.42136 20.7277 5.46541 20.7277 5.51114V5.51114M16.3544 0.888916V4.71691C16.3544 5.15555 16.71 5.51114 17.1486 5.51114H20.7277"
        stroke="#221ECD"
        strokeWidth="0.907616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5081 7.11108H9.38867"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.921 9.77771H9.38867"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.921 12.4445H9.38867"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.921 15.1111H9.38867"
        stroke="#221ECD"
        strokeWidth="0.853231"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilesIcon;
